import happyIcon from '../../assets/images/issues/happy_disabled_icon.svg'
import CardMessage from '../CardMessage'

export default {
  name: 'IssueSuccess',
  props: ['slug'],
  components: {
    CardMessage,
  },

  data() {
    return {
      happyIcon,
    }
  },
}
